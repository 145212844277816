@import "variabler";

body {
    background: $bg-color;
    font-size: $rem-multiplier;
    width: 100%;
    line-height: 1.6;
}


/* Elementer */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $mono-font-stack;
    color: $text-color;
    margin: 0.5rem 0;
    font-weight: 500;
    font-size: 1.3rem;
    text-shadow: 1px 1px 0px $bg-color, 2px 2px 0px rgba($color: $primary, $alpha: .3);
}

::selection{
    background: rgba($color: $primary, $alpha: .7);
}

p {
    font-family: $mono-font-stack;
    color: $text-color;
    font-weight: 400;
    font-size: 1.1rem;
    margin: 0.8rem 0;
}

a {
    font-family: $mono-font-stack;
    color: $text-color;
    text-decoration: none;

    svg{
        color: $text-color;
    }

    &:hover {
        color: $primary;
    }
    &:hover svg{
        color: $primary;
    }

    &:visited {
        color: $text-color;
    }
}

img{
    display: block;
    width: 100%;
    height: auto;
}

.text-link {
    background:
        linear-gradient(to bottom, $primary 0%,
            $primary 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 1.5px 1.5px;
    text-decoration: none;
    transition: 200ms ease-in-out;
}

.text-link:hover {
    background-size: 1px 50px;
    color: $bg-color;
}

.active{
    color: $primary !important;
}

.wrapper {
    max-width: 1200px;
    padding: 1em 2em;
    margin: 0 auto;
}

/* Navigationsbar */
.navbar {
    .wrapper {
        max-width: 1200px;
        padding: 1em 2em;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 992px) {
            flex-wrap: wrap;
        }
    }

    .logo a {
        font-size: 1.5rem;
        color: $text-color;
        font-family: $logo-font;
        font-weight: 400;
        text-shadow: 1px 1px 0px $bg-color, 2px 2px 0px rgba($color: $primary, $alpha: .3);
    }

    .links {
        display: flex;
        align-items: center;

        ul {
            list-style: none;
            padding: 0;
            display: flex;

            @media screen and (max-width: 992px){
                flex-direction: column;
                align-items: flex-end;
            }

            li {
                margin: 0 0.8em;

                a {
                    font-family: $mono-font-stack;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }
        }

        .social-links {
            padding-left: 2rem;

            svg {
                height: 18px;
                width: 18px;
            }
        }

        @media screen and (max-width: 992px) {
            display: none;
            width: 100%;

            .social-links{
                display: none;
            }
        }
    }

    .nav-menu {
        display: none;
        color: $primary;

        @media screen and (max-width: 992px) {
            display: block;
        }
    }
}

/* Intro section */
.intro-section {
    padding: 5em 1em;
    text-align: center;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2393C088' fill-opacity='0.5' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

.subintro-section{
    p{
        max-width: 600px;
        font-size: 1rem;
    }
}

/* Seneste projekter */
.latest-section{
    padding: 4rem 0 2rem;
    h2{
        text-align: center;
        color: $text-color;
        text-shadow: 1px 1px 0px $bg-color, 2px 2px 0px rgba($color: $primary, $alpha: .3);
    }

    .projekt-item:nth-of-type(n+4){
        display: none;
    }
}

/* Projekt Grid */
.projekt-grid{
    display: grid;
    margin-top: 3rem;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    @media screen and (max-width: 992px) {
        grid-template-columns: 1fr;
    }
}

.projekt-item{
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2393C088' fill-opacity='0.7' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    

    &:hover .projekt-info{
        height: 75px;
        transition: 300ms ease-in-out;
        padding: 1em 1em;
    }

    &:hover .projekt-info p{
        transition: 200ms ease-in-out;
        transition-delay: 300ms;
        opacity: 1;
    }
    &:hover .projekt-info .lead {
        transition: 200ms ease-in-out;
        transition-delay: 400ms;
        opacity: 1;
    }
    &:hover .projekt-info a {
        transition: 200ms ease-in-out;
        transition-delay: 600ms;
        opacity: 1;
    }
}

.projekt-info {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0px;
    width: 100%;
    color: $text-color;
    background: darken($bg-color,5%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: 300ms ease-in-out;
    transition-delay: 100ms;

    p{
        margin: 0;
        font-size: 0.875rem;
        opacity: 0;
        
    }
    a{
        opacity: 0;
    }
    svg{
        stroke-width: 1;
    }
    .lead{
        font-size: 0.8rem;
        font-weight: 400;
        color: $primary;
        opacity: 0;
    }
}
/* Footer Section */
.footer-section{
    
    padding: 3rem 0;

    h2{
        font-size: 1.2rem;
    }

    p{
        font-size: 1rem;
        margin: 0;
    }

}
.copyright{
    display: flex;
    align-items: center;
    padding-top: 2rem;
    opacity: 0.5;

    @media screen and (max-width: 992px) {
        justify-content: center;
    }

    svg{
        color: $primary;
        height: 16px;
        width: 16px;
    }
    span{
        margin-left: 5px;
        color: $primary;
        font-family: $mono-font-stack;
        font-size: 12px;
    }
}

/* Galleri */
.galleri {
    column-count: 3;
  }
  
  .galleri-item {
    width: 100%;
    margin: 0 auto 1em;
    transition: 400ms ease-in-out;

    &:hover{
        transform: scale(0.985);
        transition: 200ms ease-in-out;
        box-shadow: 0px 12px 20px -10px rgba(0,0,0,0.2);
      }
  }  
  .galleri-item img {
    max-width: 100%;
    height: auto;
  }

  /* Om */
  .info-box {
    display: grid;
    padding: 5em 0;
    grid-template-columns: 300px 1fr;
    column-gap: 5em;

    @media screen and(max-width: 992px){
        grid-template-columns: 1fr;
        grid-template-rows: 300px 1fr;
        row-gap: 3em;
    }
}
.info-img{
    position: relative;
    height: fit-content;
    &::before{
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;   
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2393C088' fill-opacity='0.33' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    }
}


.info-tekst{
    p{
        font-size: 0.975rem;
        max-width: 650px;
    }

    .kompetencer{
        display: flex;
        justify-content: space-between;
        max-width: 600px;

        @media screen and (max-width: 992px){
            flex-flow: wrap;

            p{
                width: 50%;
            }
        }
    }
}
.cookieinfo{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 1.2rem;
    max-width: 992px;
    margin: 0 auto;

    @media screen and (max-width:992px) {
        flex-direction: column-reverse;
    }
}