/* Faver*/
$bg-color: #24262B;
$text-color: #f5f5f5;
$primary: #93C088; 




/* utility */
$rem-multiplier: 16px;
$logo-font: 'Roboto Slab', sans-serif;
$mono-font-stack: 'Roboto Mono', sans-serif;